import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SVG from 'react-inlinesvg';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { filter, find } from 'lodash';

import Layout from '../components/Layout';
import ProjectItem from '../components/ProjectItem';

import BackgroundSrc from '../img/svg/pageServices/hero_bg.svg';
import Illustration from '../img/svg/pageKnowledge/hero_illustration.svg';
import ImageStarsSrc from '../img/svg/stars.svg';

const categories = [
  {
    name: "Cloud",
    data: "cloud"
  },
  {
    name: "MLOps",
    data: "mlops"
  },
  {
    name: "DataOps",
    data: "dataops"
  },
  {
    name: "Streaming",
    data: "streaming"
  },
];

class ProjectsPageTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFiltered: [],
      selectedCategory: categories[0].data,
      isMainSVGLoaded: false
    };
  }

  componentDidMount() {
    this.getFilteredData();

    // set selected tab from url hash
    let hash = window.location.hash;
    if (hash) {
      hash = hash.substring(1).replace(/%20/g, " ");
      let category = find(categories, { data: hash });
      if (category) {
        this.handleTab(hash)
      }
    }
  }

  handleTab(e) {
    this.setState({
      selectedCategory: e,
    }, () => {
      this.getFilteredData();
    });
    window.location.hash = e;
  }

  tabSelected(e) {
    let { selectedCategory } = this.state;
    return e === selectedCategory ? true : false
  }

  getFilteredData() {
    let { projects } = this.props;
    let { selectedCategory } = this.state;

    let data = filter(projects?.items, function (o) {
      return o.category == selectedCategory;
    });

    this.setState({ dataFiltered: data });
  }

  render() {
    const { dataFiltered, isMainSVGLoaded } = this.state;
    const { hero } = this.props;

    const classNameBackground = classNames('Projects__hero__background', {
      'Projects__hero__background--noImage': !isMainSVGLoaded
    });

    return (
      <div className="Projects">
        <section className="Projects__hero">
          <div className="Layout__background">
            <SVG src={ImageStarsSrc} className="Layout__stars" />
          </div>

          <div className={classNameBackground}>
            <SVG
              src={BackgroundSrc}
              uniquifyIDs={false}
              className="Projects__hero__background-image"
              onError={error => console.log(error.message)}
              onLoad={(src, hasCache) => this.setState({ isMainSVGLoaded: true })}
            />
          </div>

          <div className="Projects__hero__container">
            <div className="Projects__hero__content">
              <div className="Projects__hero__content__top">
                <h1 className="Projects__hero__title">
                  <ReactMarkdown
                    children={hero.title}
                    rehypePlugins={[rehypeRaw]}
                  />
                </h1>
                <div className="Projects__hero__text">
                  <ReactMarkdown
                    children={hero.description}
                    rehypePlugins={[rehypeRaw]}
                  />
                </div>
              </div>
            </div>

            <div className="Projects__hero__illustration">
              {/* <img
                src={Illustration}
                alt="servers"
                className="Projects__hero__image"
              /> */}
            </div>

          </div>
        </section>

        <section className="Projects__items">
          <div className="Projects__container">
            <div className="Projects__container__tags">
              <div className="Projects__tags">
                {categories != null && categories.map((e, i) => (
                  <div
                    key={i}
                    className={classNames('Projects__tags__item', {
                      'Projects__tags__item--selected': this.tabSelected(e.data)
                    })}
                    onClick={() => this.handleTab(e.data)}
                  >
                    {e.name}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="Projects__itemsList">
            {dataFiltered.map((item, i) => (
              <div key={i} className="Projects__items__item">
                <div className="Projects__container">
                  <ProjectItem
                    title={item.title}
                    imageSrc={item.image}
                    buttons={[item.button]}
                    technologies={item.technologies}
                    titleWithText={true}
                    reverse={i % 2 === 0 ? true : false}
                  >
                    {item.text}
                  </ProjectItem>
                </div>
              </div>
            ))}
          </div>
        </section>

      </div>
    )
  }
}

const ProjectsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout variants={['backgrounded']} meta={frontmatter.meta}>
      <ProjectsPageTemplate {...frontmatter} />
    </Layout>
  );
};

ProjectsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProjectsPage;

export const ProjectsPageQuery = graphql`
  query ProjectsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "projects-page" } }) {
      frontmatter {
        meta {
          title
          description
          image {
            publicURL
            name
          }
        }

        hero {
          title
          description
        }

        projects {
          text
          items {
            image {
              publicURL
              name
            }
            category
            title
            text
            technologies
            button {
              text
              url
            }
          }
        }
      }
    }
  }
`;
